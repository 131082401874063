.TrafficNow {
  color: var(--color-text);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  :global {
    .sec {
      padding: 10px;
      width: 180px;
      div:nth-child(1) {
        color: var(--color-text-secondary);
        font-size: 0.7em;
      }
      div:nth-child(2) {
        padding: 10px 0 0;
        font-size: 1.8em;
      }
    }
  }
}
