@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Roboto Mono Regular '), local('Roboto Mono-Regular'),
    url('../../node_modules/typeface-roboto-mono/files/roboto-mono-latin-400.woff2')
      format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Open Sans Regular '), local('Open Sans-Regular'),
    url('../../node_modules/typeface-open-sans/files/open-sans-latin-400.woff2')
      format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Open Sans Bold '), local('Open Sans-Bold'),
    url('../../node_modules/typeface-open-sans/files/open-sans-latin-700.woff2')
      format('woff2');
}

.relative {
  position: relative;
}
/* .absolute { */
/*   position: absolute; */
/* } */

.border-left,
.border-top,
.border-bottom {
  position: relative;
}

%border {
  position: absolute;
  content: '';
  height: 1px;
  width: 100%;
  transform: scaleY(0.5) translateZ(0);
  left: 0;
  right: 0;
  background: #555;
}

%border1 {
  position: absolute;
  content: '';
  height: 100%;
  width: 1px;
  transform: scaleX(0.5) translateZ(0);
  top: 0;
  bottom: 0;
  background: #555;
}

.border-top::before {
  @extend %border;
  top: 0;
}

.border-bottom::after {
  @extend %border;
  bottom: 0;
}

.border-left::before {
  @extend %border1;
  left: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --font-mono: 'Roboto Mono', Menlo, monospace;
  --font-normal: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    'PingFang SC', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  --color-focus-blue: #1a73e8;
}

body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, 'PingFang SC', 'Microsoft YaHei', '微软雅黑', Arial,
    sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

body,
body.dark {
  --color-background: #202020;
  --color-text: #ddd;
  --color-text-secondary: #ccc;
  --color-text-highlight: #fff;
  --color-bg-sidebar: #2d2d30;
  --color-sb-active-row-bg: #494b4e;
  --color-input-bg: #2d2d30;
  --color-input-border: #3f3f3f;
  --color-toggle-bg: #353535;
  --color-toggle-selected: #181818;
  --color-icon: #c7c7c7;
  --color-separator: #333;
  --color-btn-bg: #232323;
  --color-btn-fg: #bebebe;
  --color-bg-proxy: #303030;
  --color-row-odd: #282828;
  --bg-modal: #1f1f20;
  --bg-near-transparent: rgba(255, 255, 255, 0.1);
  --select-border-color: #040404;
  --select-bg-hover: url(data:image/svg+xml,%0A%20%20%20%20%3Csvg%20width%3D%228%22%20height%3D%2224%22%20viewBox%3D%220%200%208%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M4%207L7%2011H1L4%207Z%22%20fill%3D%22%23ffffff%22%20%2F%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M4%2017L1%2013L7%2013L4%2017Z%22%20fill%3D%22%23ffffff%22%20%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E%0A%20%20);
}

body.light {
  --color-background: #fbfbfb;
  --color-text: #222;
  --color-text-secondary: #646464;
  --color-text-highlight: #040404;
  --color-bg-sidebar: #e7e7e7;
  --color-sb-active-row-bg: #d0d0d0;
  --color-input-bg: #ffffff;
  --color-input-border: #c0c0c0;
  --color-toggle-bg: #ffffff;
  --color-toggle-selected: #d7d7d7;
  --color-icon: #5b5b5b;
  --color-separator: #ccc;
  --color-btn-bg: #f4f4f4;
  --color-btn-fg: #101010;
  --color-bg-proxy: #e7e7e7;
  --color-row-odd: #f5f5f5;
  --bg-modal: #fbfbfb;
  --bg-near-transparent: rgba(0, 0, 0, 0.1);
  --select-border-color: #999999;
  --select-bg-hover: url(data:image/svg+xml,%0A%20%20%20%20%3Csvg%20width%3D%228%22%20height%3D%2224%22%20viewBox%3D%220%200%208%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M4%207L7%2011H1L4%207Z%22%20fill%3D%22%23222222%22%20%2F%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M4%2017L1%2013L7%2013L4%2017Z%22%20fill%3D%22%23222222%22%20%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E%0A%20%20);
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* TODO remove fabgrp in component css files */
.fabgrp {
  position: fixed;
  z-index: 3;
  right: 20px;
  bottom: 20px;
}
