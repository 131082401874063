h2.sectionNameType {
  margin: 0;
  font-size: 1.3em;
  @media (--breakpoint-not-small) {
    font-size: 1.5em;
  }

  span:nth-child(2) {
    font-size: 12px;
    color: #777;
    font-weight: normal;
    margin: 0 0.3em;
  }
}

/**
 * loading dots
 *
 * light
 * dot color is #000 (black)
 *
 * transparency change:
 *          dot1  dot2  do3
 * phase1   0.1   0.3   0.5
 * phase2   0.5   0.1   0.3
 * phase3   0.3   0.5   0.1
 *
 * dark
 * dot color is #fff (white)
 *
 * transparency change:
 *          dot1  dot2  do3
 * phase1   0.5   0.3   0.1
 * phase2   0.1   0.5   0.3
 * phase3   0.3   0.1   0.5
 *
 */

:global {
  body.light {
    /*
     * --loading-dot-{dot-index}-{dot-keyframe-phase}
     */
    --loading-dot-1-1: rgba(0, 0, 0, 0.1);
    --loading-dot-1-2: rgba(0, 0, 0, 0.5);
    --loading-dot-1-3: rgba(0, 0, 0, 0.3);
    --loading-dot-2-1: rgba(0, 0, 0, 0.3);
    --loading-dot-2-2: rgba(0, 0, 0, 0.1);
    --loading-dot-2-3: rgba(0, 0, 0, 0.5);
    --loading-dot-3-1: rgba(0, 0, 0, 0.5);
    --loading-dot-3-2: rgba(0, 0, 0, 0.3);
    --loading-dot-3-3: rgba(0, 0, 0, 0.1);
  }
  body.dark {
    --loading-dot-1-1: rgba(255, 255, 255, 0.5);
    --loading-dot-1-2: rgba(255, 255, 255, 0.1);
    --loading-dot-1-3: rgba(255, 255, 255, 0.3);
    --loading-dot-2-1: rgba(255, 255, 255, 0.3);
    --loading-dot-2-2: rgba(255, 255, 255, 0.5);
    --loading-dot-2-3: rgba(255, 255, 255, 0.1);
    --loading-dot-3-1: rgba(255, 255, 255, 0.1);
    --loading-dot-3-2: rgba(255, 255, 255, 0.3);
    --loading-dot-3-3: rgba(255, 255, 255, 0.5);
  }
}

.loadingDot,
.loadingDot:before,
.loadingDot:after {
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  font-size: 0;
}

.loadingDot {
  $d: 1s;

  position: relative;
  background-color: var(--loading-dot-2-1);
  animation: dot2 $d step-start infinite;
  &:before {
    content: '';
    position: absolute;
    left: -12px;
    background-color: var(--loading-dot-1-1);
    animation: dot1 $d step-start infinite;
  }
  &:after {
    content: '';
    position: absolute;
    right: -12px;
    background-color: var(--loading-dot-3-1);
    animation: dot3 $d step-start infinite;
  }
}
/* prettier-ignore */
@keyframes dot1 {
  0%, 100% { background-color: var(--loading-dot-1-1) }
  33% { background-color: var(--loading-dot-1-2) }
  66% { background-color: var(--loading-dot-1-3) }
}
/* prettier-ignore */
@keyframes dot2 {
  0%, 100% { background-color: var(--loading-dot-2-1) }
  33% { background-color: var(--loading-dot-2-2) }
  66% { background-color: var(--loading-dot-2-3) }
}
/* prettier-ignore */
@keyframes dot3 {
  0%, 100% { background-color: var(--loading-dot-3-1) }
  33% { background-color: var(--loading-dot-3-2) }
  66% { background-color: var(--loading-dot-3-3) }
}
