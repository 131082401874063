.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pulse {
  --color1: #dddddd;
  --size: 40px;
  width: var(--size);
  height: var(--size);
  margin: 10px;
  background-color: var(--color1);
  border-radius: 100%;
  animation: pulseScaleOut 1s infinite ease-in-out;
}

@keyframes pulseScaleOut {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
