.ToggleSwitch {
  user-select: none;
  border: 1px solid #525252;
  color: var(--color-text);
  background: var(--color-toggle-bg);
  display: flex;
  position: relative;
  outline: none;

  &:focus {
    border-color: var(--color-focus-blue);
  }

  input {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  label {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    cursor: pointer;
  }
}

.slider {
  z-index: 1;
  position: absolute;
  display: block;
  left: 0;
  height: 100%;
  transition: left 0.2s ease-out;
  background: var(--color-toggle-selected);
}
