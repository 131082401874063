.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #444;
  z-index: 1024;
}

.content {
  outline: none;
  position: relative;
  color: #ddd;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #444;
  padding: 20px;
  border-radius: 10px;
}
