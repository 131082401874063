.root {
  &:focus {
    outline: none;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    color: #2d2d30;
    opacity: 0.4;
    transition: opacity 400ms;
    &:hover {
      opacity: 0.7;
    }
  }
}

.body {
  padding: 15px 0 0;
}

.hostnamePort {
  display: flex;

  div {
    flex: 1 1 auto;
  }

  div:nth-child(2) {
    flex-grow: 0;
    flex-basis: 120px;
    margin-left: 10px;
  }
}

.error {
  height: 20px;
  font-size: 0.8em;
  color: #ff8b8b;
}

.footer {
  padding: 5px 0 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
