.content.content {
  background: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  transform: none;
  padding: 0;
  border-radius: 0;

  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.container {
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
}

.overlay.overlay {
  background: #222;
}
